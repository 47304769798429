// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./icon/pop-out.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gx-link-color{\n    cursor: pointer;\n    color: red;\n    font-weight: normal;\n    font-size: 14px;\n}\n\n.text-error{\n    color: red;\n}\n\n.card-title-cursor {\n    cursor: pointer;\n    width: 15px;\n}\n\n.card-title-cursor::after {\n    content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    position: relative;\n    bottom: -5px;\n    left: 5px;\n}", "",{"version":3,"sources":["webpack://src/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;IACV,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,gDAAkC;IAClC,kBAAkB;IAClB,YAAY;IACZ,SAAS;AACb","sourcesContent":[".gx-link-color{\n    cursor: pointer;\n    color: red;\n    font-weight: normal;\n    font-size: 14px;\n}\n\n.text-error{\n    color: red;\n}\n\n.card-title-cursor {\n    cursor: pointer;\n    width: 15px;\n}\n\n.card-title-cursor::after {\n    content: url(\"./icon/pop-out.svg\");\n    position: relative;\n    bottom: -5px;\n    left: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
