import React from "react";
import { Route, Switch } from "react-router-dom";
import "./styling.css";
import asyncComponent from "util/asyncComponent";

const App = ({ match, role }) => (
  <div className="gx-main-content-wrapper">
    {role === "company" ? (
      <Route
        excat
        path={`${match.url}`}
        component={asyncComponent(() => import("./CompanyRoute"))}
      />
    ) : role === "excemployee" ? (
      <Route
        excat
        path={`${match.url}`}
        component={asyncComponent(() => import("./ExeRoute"))}
      />
    )  : (
      <Route
        excat
        path={`${match.url}`}
        component={asyncComponent(() => import("./EmployeeRoute"))}
      />
    )}
  </div>
);

export default App;
// {role==="company"?<Route excat path={`${match.url}`} component={asyncComponent(() => import("./AdminRoutes"))}/>:role==="doctor"?<Route excat path={`${match.url}`} component={asyncComponent(() => import("./doctorRoutes"))}/>:role==="practice"?<Route excat path={`${match.url}`} component={asyncComponent(() => import("./practiceRoutes"))}/>:<Route excat path={`${match.url}`} component={asyncComponent(() => import("./SaleRoutes"))}/>}
