import React from "react";
import {Avatar, Popover} from "antd";
import {logoutUser} from "../../../appRedux/actions/AuthActions";
import {connect} from "react-redux";
import { Link } from "react-router-dom";

const UserProfile = (props) => {
  const userMenuOptions = (
    <ul className="gx-user-popover">
   
      <li onClick={() => props.logoutUser()}>Logout</li>
<li>
          <Link style={{color:"black"}} to="/employee/config">Edit Profile</Link>
        </li>       

    </ul>
  );
console.log(props.auth)
  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
           <Avatar src={props.auth.us.logo?props.auth.us.logo:"https://via.placeholder.com/150"} className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
        <span className="gx-avatar-name">{props.auth.user.name}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>
  )
};

const mapStateToProps = state => ({
  auth: state.authR,
  
})


export default connect(mapStateToProps,{logoutUser})(UserProfile);
