import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
const EmployeeRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      (auth.isAuthenticated === true && (auth.user.role=="executive" || auth.user.role=="estimator" || auth.user.role=="projectmanager")) ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);
EmployeeRoute.propTypes = {
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.authR
});
export default connect(mapStateToProps)(EmployeeRoute);
