import React,{useEffect} from "react";
import {Button, Checkbox, Form,  Input} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import setAuthToken from "../util/setAuthToken";
import jwt_decode from "jwt-decode";
//import logo from "aassets/logo.png"

import {
 
  loginUser
 
} from "../appRedux/actions/AuthActions";

const FormItem = Form.Item;

class SignIn extends React.PureComponent {
 

  componentDidMount() {
  
    if (this.props.auth.isAuthenticated) {
    if (this.props.auth.user.role == "company" || this.props.auth.user.role == "administrator") {
      this.props.history.push('/company/projects');
    }
    else if(this.props.auth.user.role == "executive" || this.props.auth.user.role == "estimator" || this.props.auth.user.role == "projectmanager" ) {
      this.props.history.push('/exeemployee/checkInOut');
    } 
    else {
console.log("enter")
      this.props.history.push('/employee/checkInOut');
    }
  }
  }

componentWillReceiveProps(nextProps) {
  if (nextProps.auth.isAuthenticated) {
        
    if (nextProps.auth.user["role"] == "company" || nextProps.auth.user["role"]== "administrator") {
      
      
      
      this.props.history.push("/company/projects"); // push user to dashboard when they login
    
    } else if(nextProps.auth.user["role"] == "executive" || nextProps.auth.user["role"] == "projectmanager" || nextProps.auth.user["role"] == "estimator") {

//this.props.getEmployee(nextProps.auth.user["id"])      
//   console.log(this.props.auth.user)
         this.props.history.push('/exeemployee/checkInOut');
       }
       else
        {
     console.log("ent");
//this.props.getEmployee(nextProps.auth.user["id"])     
      this.props.history.push("/employee/checkInOut"); // push user to dashboard when they login
    }
  }

  if (nextProps.errors) {
    this.setState({
      errors: nextProps.errors
    });
  }
}
handleSubmit = (values) => {
 // e.preventDefault();
      this.props.loginUser(values);
  
};

  render() {
    
    return (
      <div className="gx-app-login-wrap" style={{marginTop:"15%"}}>
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">

              <img src="https://s3.us-east-1.wasabisys.com/constructions/static/logo.svg" alt='Neature'/>
            </div>
            <div className="gx-app-logo-wid">
            
            </div>
       
          </div>
          <div className="gx-app-login-content">
          <Form
              initialValues={{remember: true}}
              name="basic"
              onFinish={this.handleSubmit}
              className="gx-signin-form gx-form-row0">

              <Form.Item
               
                rules={[{required: true, message: 'The input is not valid E-mail!'}]} name="email">
                <Input placeholder="Email"/>
              </Form.Item>
              <Form.Item
             
                rules={[{required: true, message: 'Please input your Password!'}]} name="password">
                <Input type="password" placeholder="Password"/>
              </Form.Item>
                <Link to="/forgot" style={{marginTop:20,marginBottom:20,padding:10}}><span >Forgot Your Password ?</span></Link>  
                <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  signIn
                </Button>
                 </Form.Item>
              </Form>
            </div>

          </div>
        </div>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  auth: state.authR,
  errors: state.errors
});
export default connect(mapStateToProps, {
  loginUser
})(SignIn);
