import { SET_CURRENT_EMPLOYEE } from "../../constants/ActionTypes";
//

const initialState = {
  employee:{},
};
export default function(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
