import axios from "axios";
import { message } from "antd";
import setAuthToken from "../../util/setAuthToken";
import jwt_decode from "jwt-decode";
import config from "../../config";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  SET_CURRENT_US,
  USER_LOADING,
  SET_CURRENT_EMPLOYEE,
} from "../../constants/ActionTypes";
// Login - get user token
export const loginUser = (userData) => async (dispatch) => {
  const res = await axios
    .post(`${config.API_URL}/auth/login`, userData)
    .then((res) => {
if(res.data.success==false){
message.error('Your Account Is InActive')
window.open(`https://www.activerestoration.com/stripe/create-checkout-session?email=`+encodeURIComponent(`${res.data.user.email}`)+`&actsAccount=${res.data.user._id}`,"_blank");
}else{
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);

      // Set current user
      dispatch(setCurrentUser(decoded));
      dispatch(setUser());
}
    })
    .catch((error) => {
      message.error("Your Email Or Password is Wrong");
      throw error;
    });

  // Save to localStorage
  // Set token to localStorage
  /*console.log(res)      
      const { token } = res.data;

     
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
     
      // Set current user
      dispatch(setCurrentUser(decoded));*/
};

export const setUser = () => async (dispatch) => {
  const res = await axios
    .get(`${config.API_URL}/auth/getme`)
    .then((res) => {
      console.log(res);

      dispatch({
        type: SET_CURRENT_US,
        payload: res.data.user,
      });
      // Set current user
    })
    .catch((error) => {
      console.log("error");
      throw error;
    });
};

export const register = (userData) => async (dispatch) => {
  console.log(userData);
  const res = await axios
    .post(`${config.API_URL}/auth/register`, userData)
    .then((res) => {
      window.location.href = "./login";
    })
    .catch((error) => {
      console.log(error.message);
      message.error("I'm sorry, this email address already exists.");
    });
  //   console.log(res)
  //window.location.href = "./login";
  /*const { token } = res.data;
 localStorage.setItem("jwtToken", token);
       // Set token to Auth header
       setAuthToken(token);
       // Decode token to get user data
       const decoded = jwt_decode(token);
 
       // Set current user
       dispatch(setCurrentUser(decoded));
 }).catch((error) => {
 message.error("Your Email Or Password is Wrong")
                     throw error;
                 });
 
 
       // Save to localStorage
       // Set token to localStorage
 /*console.log(res)      
       const { token } = res.data;
 
      
       localStorage.setItem("jwtToken", token);
       // Set token to Auth header
       setAuthToken(token);
       // Decode token to get user data
       const decoded = jwt_decode(token);
      
       // Set current user
       dispatch(setCurrentUser(decoded));*/
};

// Set logged in user
export const setCurrentUser = (decode) => {
  return {
    type: SET_CURRENT_USER,
    payload: decode,
  };
};
// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};
// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

export const upgradeSubcontractor = (userid) => async (dispatch) => {
console.log(userid)
  const res = await axios
    .get(`${config.API_URL}/auth/update/Subcontrator/${userid}`)
    .then((res) => {
console.log(res)
if(res.data.success==false){
message.error("user not found");
return;
}
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);

      // Set current user
      dispatch(setCurrentUser(decoded));
      dispatch(setUser());
      window.location.replace("/company/projects");
    })
    .catch((error) => {
      message.error("there is some problem");
      throw error;
    });
};
