import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from "../../../constants/ThemeSetting";

const SidebarLogo = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { width, themeType } = useSelector(({ settings }) => settings);
  let navStyle = useSelector(({ settings }) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }

  return (
    <div className="gx-layout-sider-header"  style={
      navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED ||
        navStyle === NAV_STYLE_MINI_SIDEBAR) &&
      width < TAB_SIZE
        ? {}
        : { backgroundColor: "#ffffff" })
    }>
      {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
        <div className="gx-linebar">
          <i
            style={{ color: "#f11823" }}
            className={`gx-icon-btn icon icon-${
              !sidebarCollapsed ? "menu-unfold" : "menu-fold"
            } ${themeType !== THEME_TYPE_LITE ? "" : ""}`}
            onClick={() => {
              setSidebarCollapsed(!sidebarCollapsed);
            }}
          />
        </div>
      ) : null}

      <Link to="/" className="gx-site-logo">
        <img
          alt=""
          style={
            navStyle === NAV_STYLE_DRAWER ||
            ((navStyle === NAV_STYLE_FIXED ||
              navStyle === NAV_STYLE_MINI_SIDEBAR) &&
            width < TAB_SIZE
              ? {}
              : { height: "40px" })
          }
          src="https://s3.us-east-1.wasabisys.com/constructions/static/logo.svg"
        />
      </Link>
    </div>
  );
};

export default SidebarLogo;
/*

        {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ?
          <img alt="lo" src={("/assets/images/w-logo.png")}/> :
          themeType === THEME_TYPE_LITE ?
            <img alt="logo1" src={("/assets/images/logo-white.png")}/> :
            <img alt="logo2" src={("/assets/images/logo.png")}/>}*/
