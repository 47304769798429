import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";
import jwt_decode from "jwt-decode";
import setAuthToken from "./util/setAuthToken";
import {
  setCurrentUser,
  logoutUser,
  setUser,
} from "./appRedux/actions/AuthActions";

import configureStore, { history } from "./appRedux/store";
//import App from "./containers/App/index";
import CompanyApp from "./containers/Company/App";
import EmployeeApp from "./containers/Employee/App";
import ExeEmployee from "./containers/ExeEmployee/App";
import CompanyRoute from "./util/CompanyRoute";
import ExeEmployeeRoute from "./util/EmployeeRoute";
import EmployeeRoute from "./util/SLEmployee";
import SignIn from "./containers/SignIn";
import Forgot from "./containers/Forgot";
import Reset from "./containers/Reset";
import SignUp from "./containers/SignUp";
import ConvertSub from "./ConvertSub/index.jsx";
const store = configureStore();

if (localStorage.jwtToken) {
  // Set auth token header auth

  const token = localStorage.jwtToken;

  setAuthToken(token);
  // Decode token and get user info and exp

  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));
  store.dispatch(setUser());
  console.log(decoded);
  /*  if(decoded.role!=="company" || decoded.role!=="subcontractor"){
    console.log("Enter");
 var id=decoded.id;
    store.dispatch(getEmployee(id));  
  }*/
  // Set user and isAuthenticated

  // Check for expired token
  console.log(decoded);
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

const NextApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <Route exact path="/" component={SignIn} />
        <Route path="/login" component={SignIn} />
        <Route path="/register" component={SignUp} />
 
        <Route path="/forgot" component={Forgot} />
        <Route path="/reset/:token" component={Reset} />
        <Switch>
          <CompanyRoute path="/company" component={CompanyApp} />
          <ExeEmployeeRoute path="/exeemployee" component={ExeEmployee} />
          
          <EmployeeRoute path="/employee" component={EmployeeApp} />
        </Switch>
      </div>
    </ConnectedRouter>
  </Provider>
);

export default NextApp;
/*
  <EmployeeRoute  path="/employee" component={EmployeeApp}/>
      <SubContractorRoute  path="/subcontractor" component={SubContractorApp}/>
    */
