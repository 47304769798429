import { SET_CURRENT_USER, SET_CURRENT_US, USER_LOADING,SET_CURRENT_EMPLOYEE } from "../../constants/ActionTypes";
//
import isEmpty from "is-empty";
const initialState = {
  isAuthenticated: false,
  user: {},
us: {},
  employee:{},
  loading: false
};
export default function(state = initialState, action) {


  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
 case SET_CURRENT_US:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        us: action.payload
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true
      };


    default:
      return state;
  }
}
