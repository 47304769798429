import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../../constants/ThemeSetting";
import IntlMessages from "../../../util/IntlMessages";
import { useSelector } from "react-redux";
const MenuItemGroup = Menu.ItemGroup;
const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed, auth }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="sample">
              <Link to="/company/dashboard">
                <i className="icon icon-apps" />
                <span>
                  <IntlMessages id="sidebar.dashboard" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="employees">
              <Link to="/company/employees">
                <i className="icon icon-profile2" />
                <span>
                  <IntlMessages id="sidebar.employees" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="customers">
              <Link to="/company/customers">
                <i className="icon icon-profile" />
                <span>
                  <IntlMessages id="sidebar.customers" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="create">
              <Link to="/company/createproject">
                <i className="icon icon-add-circle" />
                <span>
                  <IntlMessages id="sidebar.createproject" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="projects">
              <Link to="/company/projects">
                <i className="icon icon-home" />
                <span>
                  <IntlMessages id="sidebar.projects" />
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key="Files">
              <Link to="/company/files">
                <i className="icon icon-files" />
                <span>
                  <IntlMessages id="sidebar.files" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="Report">
              <Link to="/company/report">
                <i className="icon icon-chart-area" />
                <span>
                  <IntlMessages id="sidebar.reports" />
                </span>
              </Link>
            </Menu.Item>
            
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state,
});

export default connect(mapStateToProps)(SidebarContent);
